
import { Component, Prop, Vue } from "vue-property-decorator";
import { IQuizResponse } from "@/types/user-result";

@Component({
  components: {},
})
export default class TestResults extends Vue {
  @Prop({ required: true }) private readonly quizResults!: IQuizResponse[];
}
