
import { Component, Prop, Vue } from "vue-property-decorator";
import { IAnomalyResponse } from "@/types/user-result";

@Component({
  components: {},
})
export default class AnomalyResults extends Vue {
  @Prop({ required: true })
  private readonly anomalyResults!: IAnomalyResponse[];
}
