
import { Component, Prop, Vue } from "vue-property-decorator";
import { ILessonResponse } from "@/types/user-result";
import TestResults from "@/components/TestResults.vue";
import AnomalyResults from "@/components/AnomalyResults.vue";

@Component({
  components: { AnomalyResults, TestResults },
})
export default class UserAnalyticCard extends Vue {
  @Prop({ required: true }) private readonly lessonResult!: ILessonResponse;

  private get createdAtDate() {
    return new Date(this.lessonResult.createdAt).toLocaleString();
  }

  private get amountOfAnomaly() {
    return this.lessonResult.anomalyResults.length;
  }

  private get score() {
    const { score } = this.lessonResult;

    return score ? score.toFixed(2) : 0;
  }

  private get correctAnomalies() {
    const { correctAnomalies } = this.lessonResult;
    return correctAnomalies ? correctAnomalies.toFixed(2) : 0;
  }

  private get averageDetectionTime() {
    const { averageDetectionTime } = this.lessonResult;
    return averageDetectionTime ? averageDetectionTime.toFixed(2) : 0;
  }
}
