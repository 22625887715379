import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"test-results"},[_c('h3',{staticClass:"test-results__title"},[_vm._v("Результаты тестов")]),_c(VExpansionPanels,_vm._l((_vm.quizResults),function(q,qIdx){return _c(VExpansionPanel,{key:qIdx},[_c(VExpansionPanelHeader,[_vm._v(" "+_vm._s(q.title)+" "),(q.isCorrect)?_c(VIcon,{attrs:{"color":"green"}},[_vm._v(" mdi-check ")]):_c(VIcon,{attrs:{"color":"red"}},[_vm._v(" mdi-close ")]),_vm._v(" Правильно: "+_vm._s(q.correctAnswers)+"/"+_vm._s(q.answers.length)+" ")],1),_c(VExpansionPanelContent,{staticClass:"test-results__content"},_vm._l((q.answers),function(a,aIdx){return _c(VCard,{key:aIdx,staticClass:"test-results__answer"},[_c(VCardText,[_vm._v(" "+_vm._s(a.question)+" "+_vm._s(a.selectedOptions.length > 1 ? "Ответы" : "Ответ")+"  "+_vm._s(a.options .filter((el, idx) => a.selectedOptions.some((o) => o === idx)) .join(", "))+" "),(a.isCorrect)?_c(VIcon,{attrs:{"color":"green"}},[_vm._v(" mdi-check ")]):_c(VIcon,{attrs:{"color":"red"}},[_vm._v(" mdi-close ")])],1)],1)}),1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }