
import { Component, Vue } from "vue-property-decorator";
import IUserResult, { ILessonResponse } from "@/types/user-result";
import { getUserResults } from "@/api/api";
import TheLoader from "@/components/TheLoader.vue";
import UserAnalyticCard from "@/components/UserAnalyticCard.vue";

@Component({
  components: { UserAnalyticCard, TheLoader },
})
export default class UserAnalyticPage extends Vue {
  private userStat: IUserResult | null = null;
  private statsLoading = true;
  private userId = 0;

  private get lessonResults(): ILessonResponse[] {
    if (this.userStat) {
      return this.userStat.lessonResults;
    }

    return [];
  }
  private goToCard(): void {
    const { hash } = this.$route;
    if (!hash) {
      return;
    }

    const id = hash.slice(1);

    const ref: any = this.$refs[id];

    if (!ref) {
      return;
    }

    const card = ref[0];

    if (!card) {
      return;
    }

    const { $el } = card;

    if ($el) {
      window.scrollTo({
        top: $el.offsetTop,
        behavior: "smooth",
      });
    }
  }
  private async getUserStats() {
    if (!this.userId) {
      return;
    }

    try {
      this.userStat = await getUserResults(this.userId);

      setTimeout(() => {
        this.goToCard();
      });
    } catch (e) {
      console.error(e);
    } finally {
      this.statsLoading = false;
    }
  }

  private mounted(): void {
    this.userId = +this.$route.params.userId;
    this.getUserStats();
  }
}
